<template>
    <div class="cus-dash-menu">
        <ul>
            <li><router-link  :to="{ name: 'customer_dashboard' }">Dashboard</router-link></li>
            <li><router-link  :to="{ name: 'customer_order' }">Your Order</router-link></li>
            <li><router-link  :to="{ name: 'order_from_history' }">Order from history</router-link></li>
            <!--<li><router-link  :to="{ name: 'my_wish_list' }">My Wishlist</router-link></li>-->
            <li @click="logout" style="cursor: pointer"><a>Logout</a></li>
        </ul>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    export default {
        computed: {
            ...mapGetters([
                "shoppingBag","total","accessToken","customer"
            ]),

        },
        methods:{
            logout() {
                this.$store.dispatch("logout");
                //window.localStorage.clear();
                this.$router.push({name: 'home'}).catch(() => {});
                location.reload();
            },
        }
    }
</script>
<style>
    .cus-dash-menu {
        background: #000;
        margin-bottom: 5px;
    }
    .cus-dash-menu ul li a {
        color: #fff;
        display: block;
        padding: 15px;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 13px;
        border-bottom: 1px solid #560000;
        text-align: left;
    }
    .cus-dash-menu ul li a:hover{
        color: red;
    }
    .cus-dash-menu ul li a.router-link-active{
        color: red;
    }
</style>
<template>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <CustomerNavbar></CustomerNavbar>
            </div>
            <div class="col-md-9">
                <div class="panel panel-default">
                    <div class="panel-heading dash-head">Ordered Information</div>
                    <div class="panel-body">
                        <template>
                            <div>
                                <table id="datatable-buttons-click" class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Product Name</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Total Amount</th>
                                            <!--<th>Status</th>-->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr role="row" class="odd" v-for="(order) in orderDetails.order_items"
                                            :key="order.id">
                                            <td class="sorting_1">
                                                {{ order.product_sku }}
                                            </td>
                                            <td>
                                                <img class="dtlImg" v-if="order.product_image_thumb"
                                                    :src="'https://eurasiasupplies.com/euadmin/' + order.product_image_thumb"
                                                    alt="" />
                                                {{ order.product_name }}
                                            </td>
                                            <td>
                                                {{ order.quantity }}
                                            </td>
                                            <td>
                                                {{ order.price }}
                                            </td>
                                            <td>
                                                {{ order.total_amount }}
                                            </td>
                                            <!--<td>
                                            {{ order.item_status===0?'cancel':
                                            order.item_status===1?'Pending':
                                            order.item_status===2?'confirm':
                                            order.item_status===3?'Hold':
                                            order.item_status===4?'shipped':
                                            order.item_status===5?'delivered':
                                            order.item_status===6?'partial delivered':''
                                            }}
                                        </td>-->

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import CustomerNavbar from './CustomerNavbar'
export default {
    computed: {
        ...mapGetters(["me", "orderDetails"]),
    },
    mounted() {
        console.log("=====================")
        this.$store.dispatch('getOrderDetails', this.$route.params.orderId);
        console.log("=====================", this.orderDetails);
    },
    components: {
        CustomerNavbar,
    },
    /*watch: {
        $route (){
            const val = this.$route.params.orderId;
            this.$store.dispatch('orderDetailsById', val);
        }
    },*/
    methods: {

    }
};

</script>